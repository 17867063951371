import api from "./apis";
import history from "../history";
import { ERROR_MESSAGE, LOADED, LOADING, SUCCESS_MESSAGE, VALIDATION_ERROR } from "./type";
import axios from "axios";

// ============Fetch Orders=====
export const fetchOrders = (shop,s,p,rmn,ds,de,mo,o) => async dispatch => {
  
  dispatch({type: LOADING});
    try {
      let response = []
      if(ds === null || de === null){
         response =  await api(localStorage.getItem("token")).get(`/orders?shop=${shop}&s=${s}&p=${p}&rmn=${rmn}&mo=${mo}&o=${o}`);
     
      }
      else{
         response =  await api(localStorage.getItem("token")).get(`/orders?shop=${shop}&s=${s}&p=${p}&rmn=${rmn}&ds=${ds ? ds : ''}&de=${de ? de : ''}&mo=${mo}&o=${o}`);
     
      }

      
      if (response.status === 200) {
        dispatch({ type: LOADED });
        return response.data;
      } else {
        dispatch({ type: LOADED });
      }
    } catch (err) {
       if(err.response)
          {
            dispatch( {type: LOADED });
              if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
              if(err.response.status === 422){ 
                // dispatch({
                //   type: ERROR_MESSAGE,
                //   payload: err.response ? err.response.data.message : history.push('/404'),
                // })}
          }
        }
      dispatch({ type: LOADED });
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response ? err.response.data.message : history.push('/404'),
      });
    }
  }

  // ==============Get Price============
  export const getPrice = (shop_id,amount_to_collect ,weight,areaCode, delivery_type) => async (dispatch) => {
    let amt = amount_to_collect ? amount_to_collect : 0;
    let wt = weight ? weight : 1000;
    let area_code = areaCode ? areaCode : '1-1-1-1'
    let dt = delivery_type ? delivery_type : 'ND';
    try{
      const response = await api(localStorage.getItem("token")).get(`/get-pricing/details?shop_id=${shop_id}&areaCode=${area_code}&amount_to_collect=${amt}&weight=${wt}&delivery_time=${dt}`);
      return response.data
      // dispatch({ type: FETCH_COST, payload: response.data });
     
    }catch(err){
      
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response
          ? err.response.data.message
          : "Request failed try again.",
      });
    }
  }
  // ============Create Order======
  export const createOrder = (cost,shop_id,data, areaCode) => async (dispatch) => {
    // dispatch({ type: LOADING });
    let dt = data.delivery_type ? data.delivery_type : 'ND';
    const obj = {
      "shop_id": shop_id,
      "merchant_order_id": data.merchant_order_id ? data.merchant_order_id : 'N/A' ,
      "recipient_name": data.recipient_name,
      "recipient_mobile_number" : data.recipient_phone,
      "recipient_address": data.recipient_address,
      "recipient_area_code": areaCode,
      "weight": data.weight,
      "quantity": data.quantity,
      "item_description": data.item_description,
      "note": data.note,
      "amount_to_collect": cost.amount_to_collect,
      "item_actual_price" : data.item_actual_price,
      "product_type": data.product_type,
      "delivery_time" :dt,
    }
   
    // dispatch({ type: LOADING });
    try {
      const response = await api(localStorage.getItem("token")).post("/create-order", {...obj});
  
      if (response.status === 201) {
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: "Your Order is created succesfully",
        });
        dispatch({ type: LOADED });
        history.push('/parcel')
        return response.status
        // history.push(`/orderList?shop_id=${shop_id}`);
      } 
    } catch (err) {
        if(err?.response?.status === 422){
          err.response.data.errors ?
          err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
            :
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response ? err.response.data.message : null ,
            });
          }
       if(err.response)
          {
            // dispatch( {type: LOADED });
              if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
          }
      // dispatch({ type: LOADED });
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response ? err.response.data.message : history.push('/404'),
      });
    }
  };
  // ============Update Order==========
  export const updateOrder = (cost,shop_id,order_id,data,areaCode) => async (dispatch) => {
  
    let dt = data.delivery_type ? data.delivery_type : 'ND';
    const obj = {
      "shop_id": shop_id,
      "order_id": order_id,
      "merchant_order_id": data.merchant_order_id ? data.merchant_order_id : 'N/A' ,
      "recipient_name": data.recipient_name,
      "recipient_mobile_number" : data.recipient_phone,
      "recipient_address": data.recipient_address,
      "recipient_area_code": areaCode,
      "weight": data.weight,
      "quantity": data.quantity,
      "item_description": data.item_description,
      "note": data.note,
      "amount_to_collect": cost.amount_to_collect,
      "item_actual_price" : data.item_actual_price,
      "product_type": data.product_type,
      "delivery_time" :dt,
    }
    // dispatch({ type: LOADING });
    try {
      const response = await api(localStorage.getItem("token")).patch("/update-order", {...obj});
  
      if (response.status === 201) {
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: "Your Order is updated succesfully",
        });
        // dispatch({ type: LOADED });
      } 
    } catch (err) {
        if(err?.response?.status === 422){
          err.response.data.errors ?
          err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
            :
            dispatch({
              type: ERROR_MESSAGE,
              payload: err.response ? err.response.data.message : null ,
            });
          }
       if(err.response)
          {
            dispatch( {type: LOADED });
              if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
          }
      // dispatch({ type: LOADED });
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response ? err.response.data.message : history.push('/404'),
      });
    }
  };
 // ============Create Buld Order======
 export const createBulkOrder = (shop_id,data, name) => async (dispatch) => {
  dispatch({ type: LOADING });
  const bulk = []
  data.map((item,id) => {
    if(item.merchant_order_id !== '' || item.recipient_name !== '' || item.recipient_mobile_number !== ''
            || item.recipient_address !== ''|| item.city !== ''|| item.area !== ''
            || item.weight !== ''|| item.product_type !== ''|| item.quantity !== ''
            || item.amount_to_collect !== ''|| item.item_description !== ''){
      // delete item.city
      // delete item.area
      bulk.push(item)
    }
  })
  const obj = {
    "shop_id": shop_id,
    "bulk": bulk,
  }
  dispatch({ type: LOADING });
  try {
    const response = await api(localStorage.getItem("token")).post("/create-bulk-order", {...obj});

    if (response.status === 201) {
      dispatch({
        type: SUCCESS_MESSAGE,
        payload: "Your Order is created succesfully",
      });
      dispatch({ type: LOADED });
      return response.status
      // history.push(`/orderList?shop_id=${shop_id}&name=${name}`);
    } else {
      dispatch({ type: LOADED });
    }
  } catch (err) {
     if(err.response)
        {
          // dispatch( {type: LOADED });
            if(err.response.status === 401){ 
              localStorage.clear(); history.push('/login') 
              dispatch({ type: LOADED });
            }
            
          }
    // dispatch({ type: LOADED });
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push('/404'),
    });
    
    dispatch({ type: LOADED });
  }
};
  // ======fetch Edit Data=======
  export const fetchEditData = (shop_id, order_id, mobile_number) => async dispatch => {
    // const obj = {
    //   "shop_id": data[0],
    //   "order_id": data[1],
    //   "recipient_mobile_number" : data[2],
    // }
  
    // dispatch({ type: LOADING });
    try {
      const response =  await api(localStorage.getItem("token")).get(`/get-edit-order?shop_id=${shop_id}&order_id=${order_id}&recipient_mobile_number=${mobile_number}`);
      
      if (response.status === 200) {
        // localStorage.setItem('editOrder', JSON.stringify(response.data))
       
  
        // dispatch({ type: LOADED });
        return response.data;
        // history.push("/order/edit");
      } 
    } catch (err) {
       if(err.response)
          {
            // dispatch( {type: LOADED });
              if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
          }
      // dispatch({ type: LOADED });
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response ? err.response.data.message : history.push('/error'),
      });
    }
  }

  // ================Cancel Order=====
  export const cancelOrder = (shop_id, order_id) => async (dispatch) => {
    // dispatch({type: LOADING});
    try {
      const response = await api(localStorage.getItem("token")).patch(`cancel-order?shop_id=${shop_id}&order_id=${order_id}`);
      if (response.status === 201) {
        
        // dispatch({ type: LOADED });
        dispatch({
          type: SUCCESS_MESSAGE,
          payload: "Order has been cancelled successfully",
        });
        return response.status
        // history.push("/orderList");
       
      }
    } catch (err) {
      if(err.response)
      {
          // dispatch( {type: LOADED });
          if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
      }
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response
          ? err.response.data.message
          : null,
      });

      // dispatch({ type: LOADED });
    }
  }

  // =============Fetch order Details====
  export const fetchOrderDetails = (shop_id, order_id, mobile_number) => async dispatch => {
    // const obj = {
    //   "shop_id": data[0],
    //   "order_id": data[1],
    //   "recipient_mobile_number" : data[2],
    // }
  
    dispatch({ type: LOADING });
    try {
      const response =  await api(localStorage.getItem("token")).get(`/get-order-detail?shop_id=${shop_id}&order_id=${order_id}&recipient_mobile_number=${mobile_number}`);
      
      if (response.status === 200) {
        // localStorage.setItem('editOrder', JSON.stringify(response.data))
       
  
        dispatch({ type: LOADED });
        return response.data;
        // history.push("/order/edit");
      } else {
        dispatch({ type: LOADED });
      }
    } catch (err) {
       if(err.response)
          {
            dispatch( {type: LOADED });
              if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
          }
      dispatch({ type: LOADED });
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response ? err.response.data.message : history.push('/error'),
      });
    }
  }
  // =============Get Odd time====
  export const fetchODDTime = () => async dispatch => {
    // dispatch({ type: LOADING });
    try {
      const response =  await api(localStorage.getItem("token")).get(`/time`);
      
      if (response.status === 200) {
        // dispatch({ type: LOADED });
        return response.data;
      } 
    } catch (err) {
       if(err.response)
          {
            // dispatch( {type: LOADED });
              if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
          }
      // dispatch({ type: LOADED });
      dispatch({
        type: ERROR_MESSAGE,
        payload: err.response ? err.response.data.message : history.push('/error'),
      });
    }
  }

  
export const bulkFileDownloader = (name) => async (dispatch) => {
  // dispatch({ type: LOADING });
  
  try{
    const response = await axios(`https://api-delivery.redorch.com/merchant/bulk/order/sample`,
        {
          headers: {
            'authorization': `Bearer ${localStorage.getItem("token")}`
            },
            method: 'GET',
            responseType: 'blob',
          }
        );
        
    if(response.status === 200 ) {
      dispatch({ type: LOADED });
      const url = window.URL.createObjectURL(new Blob([response.data]),);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `merchant_sample.xlsx`,
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  }
  catch( err ){
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        if(err?.response?.status === 451)
          {
            history.push('/pending-verification')
          }
        
        if(err.message == 'Network Error')
        {
            history.push('/maintenance')
            return
        }

        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/404') });
        dispatch({type: LOADED});
      /* ===========End============*/
    }
}