import styled from "styled-components";

export const FlexContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    
`;
export const LoginContainer = styled.div`
    align-self: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 550px;
    background-color: #fff;
    /* height: auto; */
    /* margin: auto; */
    box-shadow: 0px 2px 16px 1px rgba(0,0,0,0.2);
    padding: 50px 60px;
    border-radius: 10px;
    align-self: center;
    img{
        width: 80px;
        align-self: center;
    }
    p{
        text-align: center;
        padding: 10px 0px;
        font-weight: 600;
    }
   
    @media only screen and (max-width: 992px) {
        /* padding: 50px 10px; */
        width: 80%;
        padding: 50px 10px;
        img{
            width: 100px ;
        }
    }
`;