import axios from 'axios';

export default (token = false) => {
    
    return axios.create({
        baseURL: 'https://api-delivery.redorch.com/merchant/',
        headers: {
             'content-type': 'application/json',
            'authorization': `Bearer ${token}`
        }
    })
}